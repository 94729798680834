<script lang="typescript">
    import { Button, Dialog, Select, DatePicker, TextField } from "smelte";
    import { DateTime } from "luxon";
    import { createTaskGroup, frequencyItems } from "../api";
    import { taskGroups } from "../stores/task_groups";

    export let division;
    export let location;
    export let token;

    let creating = false;
    let disabled = false;

    let executionDate = new Date();
    let selectedFrequency = null;
    let users = [
        {
            first_name: "",
            last_name: "",
            email: "",
        },
    ];

    const onSubmit = async () => {
        disabled = true;
        const data = {
            location: {
                id: location.id,
            },
            relation: {
                id: location.relation.id,
            },
            management_type: selectedDivision.management_type,
            frequency_type: selectedFrequency,
            division: {
                id: selectedDivision.id,
            },
            execution_date: DateTime.fromJSDate(executionDate).toISODate(),
            users: users,
        };
        const newGroup = await createTaskGroup(data, token);
        if (!("reason" in newGroup)) {
            taskGroups.update((existing) => [...existing, newGroup]);
        }
        disabled = false;
        creating = false;
    };

    const onCancel = async () => {
        creating = false;
    };

    let divisionOptions = location.divisions.map((item) => ({
        text: item.name,
        value: item,
    }));
    let selectedDivision = division;

    const onAddUser = () => {
        users = [
            ...users,
            {
                first_name: "",
                last_name: "",
                email: "",
            },
        ];
    };

    const onRemove = (index) => {
        users = users.slice(0, index).concat(users.slice(index + 1));
    };
</script>

<div class="text-center mt-8">
    <Button on:click={() => (creating = true)} disabled={creating}>
        Create new task group
    </Button>
</div>

<Dialog
    bind:value={creating}
    classes="items-center z-50 rounded bg-white dark:bg-dark-400 p-4 elevation-4 max-w-95">
    <h5 slot="title">Plan new group</h5>
    <form>
        <Select
            label="Division"
            items={divisionOptions}
            bind:value={selectedDivision} />
        <Select
            label="Frequency"
            items={frequencyItems}
            bind:value={selectedFrequency} />
        <DatePicker bind:value={executionDate} />
        <fieldset>
            <legend>Users</legend>
            {#each users as user, index}
                <div class="flex">
                    <div class="flex-1">
                        <TextField
                            bind:value={user.first_name}
                            label="First name" />
                    </div>
                    <div class="flex-1 mx-4">
                        <TextField
                            bind:value={user.last_name}
                            label="Last name" />
                    </div>
                    <div class="flex-1">
                        <TextField
                            bind:value={user.email}
                            label="Email"
                            type="email" />
                    </div>
                    <div class="flex-initial px-4 self-center">
                        <Button
                            icon="clear"
                            on:click={() => onRemove(index)}
                            small />
                    </div>
                </div>
            {/each}
            <div class="flex justify-end">
                <div>
                    <Button text success on:click={onAddUser}>Add</Button>
                </div>
            </div>
        </fieldset>
    </form>
    <div slot="actions">
        <Button text danger on:click={onCancel} {disabled}>Cancel</Button>
        <Button text on:click={onSubmit} {disabled}>Plan</Button>
    </div>
</Dialog>
