<script lang="ts">
  import { authToken } from "../stores/auth";
  import type { TaskGroupInformation } from "../stores/types";
  import JSONTree from "svelte-json-tree";
  import { Button, ProgressCircular, Icon } from "smelte";
  import { getTaskGroupPlanForm, planTaskGroup } from "../api";

  import { Form, components } from "@pyoner/svelte-form";
  import { createAjvValidator } from "@pyoner/svelte-form-ajv";
  import Ajv from "ajv";
  import cloneDeep from "lodash/fp/cloneDeep";

  import Select from "./form/fields/select.svelte";
  import AddItem from "./form/add_item.svelte";
  import ItemCtrl from "./form/item_ctrl.svelte";

  import { DateTime } from "luxon";

  let testComponents = {
    ...components,
    fields: {
      ...components.fields,
      string: Select,
      "custom-date": Select,
    },
    addItem: AddItem,
    itemCtrl: ItemCtrl,
  };

  export let taskGroupInformation: TaskGroupInformation;

  let initialData = null;
  let form = null;
  let formData = null;
  let submitting = false;
  let submitted = false;
  let error = null;

  const ajv = new Ajv({
    schemaId: "auto",
    jsonPointers: true,
    allErrors: true,
    coerceTypes: true,
  });
  const validator = createAjvValidator(ajv);

  authToken.subscribe(async (value) => {
    if (value === "") {
      form = null;
      return;
    }

    initialData = await getTaskGroupPlanForm(
      taskGroupInformation.location.id,
      taskGroupInformation.id,
      value
    );

    if ("reason" in initialData) {
      error = initialData.reason;
      submitted = true;
    } else {
      form = cloneDeep(initialData.form);
      formData = cloneDeep(initialData.data);
    }
  });

  async function submit(e) {
    submitting = true;

    const result = await planTaskGroup(
      taskGroupInformation.location.id,
      taskGroupInformation.id,
      e.detail,
      $authToken
    );

    taskGroupInformation = result;
    form = null;

    submitting = false;
    submitted = true;
  }
</script>

{#if form === null && !submitted}
  <div class="flex justify-center">
    <ProgressCircular />
  </div>
{:else if !submitted}
  <Form
    schema={form}
    bind:value={formData}
    {validator}
    components={testComponents}
    on:submit={submit}
    on:reset={(e) => {
      formData = cloneDeep(initialData.data);
    }}>
    <div class="flex justify-end">
      <div class="flex-initial mr-4">
        <Button type="reset" text disabled={submitting}>Reset</Button>
      </div>
      <div class="flex-initial">
        <Button type="submit" disabled={submitting}>
          {#if submitting}
            <ProgressCircular size="20" width="1" />
          {:else}Submit{/if}
        </Button>
      </div>
    </div>
  </Form>
  <hr class="my-12 border-1" />
  <div class="flex">
    <div class="flex-1">
      <JSONTree value={formData} />
    </div>
    <div class="flex-1">
      <JSONTree value={form} />
    </div>
  </div>
{:else}
  <div class="flex flex-col items-center mt-8">
    {#if error !== null}
      <div class="flex-initial">
        <Icon color="red">clear</Icon>
      </div>
      <div class="flex-initial">
        <p class="text-error">{error}</p>
      </div>
    {:else}
      <Icon color="green" class="icon-xl">done</Icon>
    {/if}
  </div>
{/if}
