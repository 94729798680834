<script lang="ts">
  import { authToken } from "../stores/auth";
  import { getTaskGroup } from "../api";
  import { slide } from "svelte/transition";
  import JSONTree from "svelte-json-tree";
  import ProgressCircular from "smelte/src/components/ProgressCircular/ProgressCircular.svelte";
  import type { TaskGroupInformation } from "../stores/types";
  import { Button, Dialog } from "smelte";
  import { DateTime } from "luxon";
  import TaskGroupForm from "../components/task_group_form.svelte";
  import { params } from "svelte-hash-router";

  const taskGroupId = $params.id;
  const locationId = $params.locationId;

  let showDialog = false;

  let taskGroupInformation: TaskGroupInformation | null = null;
  authToken.subscribe(async (token) => {
    if (token === "") {
      taskGroupInformation = null;
      return;
    }
    taskGroupInformation = await getTaskGroup(locationId, taskGroupId, token);
  });
</script>

{#if taskGroupInformation === null}
  <div out:slide class="flex justify-center">
    <ProgressCircular />
  </div>
{:else}
  <div class="flex">
    <div class="flex-1">
      <h5>
        {taskGroupInformation.division?.name || taskGroupInformation.management_type}
        in phase
        {taskGroupInformation.status}
        with
        {taskGroupInformation.tasks.length}
        tasks
      </h5>
      <span class="subtitle-1">
        Execution date:
        {taskGroupInformation.execution_date.toLocaleString(DateTime.DATETIME_FULL)}
      </span>
    </div>
    <div class="flex-initial">
      <Button icon="visibility" small on:click={() => (showDialog = true)} />
    </div>
  </div>

  <TaskGroupForm {taskGroupInformation} />

  <Dialog
    bind:value={showDialog}
    classes="items-center z-50 rounded bg-white dark:bg-dark-400 p-4 elevation-4 max-w-95">
    <div class="max-h-3/4-screen overflow-auto">
      <JSONTree value={taskGroupInformation} />
    </div>
  </Dialog>
{/if}
