<script lang="ts">
  import { authToken } from "./stores/auth";
  import Header from "./header.svelte";
  import { routes, Router } from "svelte-hash-router";
  import Log from "./components/log.svelte";
</script>

<style lang="postcss">
</style>

<div class="h-screen overflow-auto flex flex-col">
  <div class="flex-initial min-h-0">
    <Header />
  </div>
  <main
    class="relative container mx-auto my-8 md:p1-16 border-1 border-gray-500 flex-1 min-h-0 overflow-auto"
    id="main-content">
    {#if $authToken === '' || $authToken === null || $authToken === undefined}
      Please sign in using the lock button in the top right corner.
    {:else}
      <Router />
    {/if}
  </main>
  <footer class="border-t-4 border-primary-500 max-h-1/4 overflow-auto min-h-0">
    <Log />
  </footer>
</div>
