import { writable } from "svelte/store";
import { authToken } from "./auth";
import { getJsonFromLocalStorage } from "../util/localStorageHelper";
const storedTaskGroups = getJsonFromLocalStorage("task_groups", []);
const storedSelectedTaskGroups = getJsonFromLocalStorage("task_groups_selected", null);
export const taskGroups = writable(storedTaskGroups);
export const selectedTaskGroup = writable(storedSelectedTaskGroups);
let currentValue = undefined;
authToken.subscribe((value) => {
    if (currentValue === undefined) {
        currentValue = value;
    }
    if (currentValue !== value) {
        currentValue = value;
        taskGroups.set([]);
        selectedTaskGroup.set(null);
    }
});
