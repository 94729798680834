<script lang="ts">
  import type { Props } from "@pyoner/svelte-form/src/types";
  import { Button } from "smelte";

  export let addItem: Function;
  export let props: Props = {};
</script>

<div class="flex justify-end">
  <div class="flex-initial">
    <Button
      on:click={(e) => {
        addItem();
        e.preventDefault();
      }}>
      Add item
    </Button>
  </div>
</div>
