import { writable } from "svelte/store";
import { authToken } from "./auth";
import { getJsonFromLocalStorage } from "../util/localStorageHelper";
const storedRelations = getJsonFromLocalStorage("relations", []);
let storedSelectedRelation = getJsonFromLocalStorage("relations_selected", null);
if (storedSelectedRelation !== null) {
    storedSelectedRelation = storedRelations.find((relation) => storedSelectedRelation.id === relation.id);
}
export const relations = writable(storedRelations);
export const selectedRelation = writable(storedSelectedRelation);
let currentValue = undefined;
authToken.subscribe((value) => {
    if (currentValue === undefined) {
        currentValue = value;
    }
    if (currentValue !== value) {
        currentValue = value;
        relations.set([]);
        selectedRelation.set(null);
    }
});
relations.subscribe((value) => {
    localStorage.setItem("relations", JSON.stringify(value));
});
selectedRelation.subscribe((value) => {
    localStorage.setItem("relations_selected", JSON.stringify(value));
});
