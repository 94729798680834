import { writable } from "svelte/store";
import { url } from "./urls";
const storedToken = localStorage.getItem("authToken");
export const authToken = writable(storedToken);
authToken.subscribe((value) => {
    if (value !== null) {
        localStorage.setItem("authToken", value);
    }
    else {
        localStorage.setItem("authToken", "");
    }
});
let initialUrl = null;
url.subscribe((value) => {
    if (initialUrl === null) {
        initialUrl = value;
        return;
    }
    if (value !== initialUrl) {
        authToken.set("");
    }
});
