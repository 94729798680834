<script lang="ts">
  import { authToken } from "./stores/auth";
  import { url } from "./stores/urls";
  import { getBranches } from "./api";
  import { TextField, Button, Dialog, ProgressCircular } from "smelte";
  import dark from "smelte/src/dark";

  let showDialog = false;
  let testing = false;
  let newToken = $authToken;

  const urls = [
    { name: "Acceptance", url: "https://acc.mijn.legionelladossier.nl" },
    { name: "Production", url: "https://app.legionelladossier.com" },
  ];

  const validateUrl = (currentUrl: string) => {
    if (!urls.map(({ url }) => url).includes(currentUrl)) {
      url.set(urls[0].url);
    }
  };

  $: validateUrl($url);

  let darkMode = dark();
  let error = null;

  if ($darkMode) {
    document.body.classList.add("mode-dark");
  }
</script>

<style lang="postcss">
  select {
  }
</style>

<header
  class="sticky top-0 w-full items-center flex-wrap flex left-0 z-30 p-0 h-16 elevation-3 bg-brand text-white">
  <div class="px-2 md:px-8 flex items-center">
    <img alt="LD Logo" src="/img/logo_white.svg" width="132" class="mr-4" />
    Public API Example
  </div>
  <div class="flex-grow" />
  <div class="pl-1 mr-4">
    <div class="border rounded bg-transparent pr-2">
      <select bind:value={$url} class="text-gray-100 bg-transparent py-2 px-2">
        {#each urls as option}
          <option
            class="text-black dark:text-gray-100 bg-gray-100 dark:bg-gray-900 px-2 py-2"
            value={option.url}>
            {option.name}
          </option>
        {/each}
      </select>
    </div>
  </div>
  <div class="pl-1 mr-2">
    <Button
      icon="wb_sunny"
      flat
      small
      on:click={() => ($darkMode = !$darkMode)} />
  </div>
  <div class="pr-2 md:pr-8">
    <Button
      icon={$authToken === '' ? 'lock' : 'lock_open'}
      small
      flat
      on:click={() => (showDialog = true)} />
  </div>
</header>

<Dialog bind:value={showDialog}>
  <TextField
    bind:value={newToken}
    label="Auth token"
    disabled={testing}
    {error} />
  <div slot="actions">
    <Button
      text
      on:click={() => {
        newToken = $authToken;
        showDialog = false;
        error = null;
      }}>
      Cancel
    </Button>
    <Button
      text
      danger
      on:click={async () => {
        if (newToken === '') {
          authToken.set(newToken);
          showDialog = false;
          return;
        }
        testing = true;
        try {
          let branches = await getBranches(newToken);
          showDialog = false;
          error = null;
          authToken.set(newToken);
        } catch (e) {
          console.warn(e);
          error = 'Invalid API token';
        }
        testing = false;
      }}
      disabled={testing}>
      {#if testing}
        <ProgressCircular size={15} width={1} />
      {:else}Save{/if}
    </Button>
  </div>
</Dialog>
