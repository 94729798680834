<script lang="ts">
  import { authToken } from "../stores/auth";
  import { selectedRelation } from "../stores/relations";
  import { locations, selectedLocation } from "../stores/locations";
  import { Select, ProgressLinear } from "smelte";
  import { getLocations, getLocationsOfRelation } from "../api";
  import { slide } from "svelte/transition";
  import type { Relation } from "../stores/types";

  let items = [];
  let loading = false;

  let currentRelation: Relation | null = null;

  selectedRelation.subscribe(async (relation) => {
    if (currentRelation === null && relation === null) {
      return;
    }
    if (currentRelation !== null && currentRelation.id === relation.id) {
      return;
    }
    loading = true;
    console.log(`relation is changed to ${relation.name} 🎊`);
    currentRelation = relation;

    $locations = await getLocationsOfRelation(currentRelation, $authToken);

    items = $locations.map((location) => ({
      value: location,
      text: `${location.name} (#${location.id})`,
    }));

    loading = false;
  });
</script>

{#if loading}
  <ProgressLinear />
{/if}

{#if items.length > 0}
  <div in:slide out:slide>
    <Select
      label="Select a location"
      outlined
      autocomplete
      {items}
      bind:value={$selectedLocation} />
  </div>
{/if}
