import { routes, Router } from "svelte-hash-router";
import Home from "./pages/home.svelte";
import TaskGroup from "./pages/task_group.svelte";
import App from "./App.svelte";
import "./tailwind.css";
routes.set({
    "/": {
        $$component: App,
        home: Home,
        "location/:locationId/task-group/:id": TaskGroup,
    },
});
console.log(location.hash);
if (location.hash === "" || location.hash === "#") {
    location.hash = "#/home";
}
export default new Router({ target: document.body });
