<script lang="ts">
  import { relations, selectedRelation } from "../stores/relations";
  import { authToken } from "../stores/auth";
  import { getRelations } from "../api";
  import { ProgressLinear, Button, Select } from "smelte";

  let loading = false;
  let items = [];
  let label = "Select a relation";

  relations.subscribe((value) => {
    if (value === null) {
      return;
    }
    items = value.map((branch) => ({
      value: branch,
      text: branch.name,
    }));
  });
</script>

{#if loading}
  <ProgressLinear />
{/if}

<div class="flex items-center">
  <div class="flex-auto mr-2">
    {#if items.length > 0}
      <Select
        bind:value={$selectedRelation}
        outlined
        autocomplete
        {label}
        {items} />
    {/if}
  </div>

  <div class="flex-initial">
    <Button
      on:click={async () => {
        loading = true;
        relations.set(await getRelations($authToken));
        loading = false;
      }}>
      Fetch
    </Button>
  </div>
</div>
