import "./stores/types";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { requestLog } from "./stores/log";
import { url } from "./stores/urls";
let definedUrl = null;
url.subscribe((value) => {
    definedUrl = value;
});
export async function getBranches(token) {
    return await customResponse("/branches", token);
}
export async function getRelations(token) {
    return await customResponse("/relations", token);
}
export async function getLocations(token) {
    return await customResponse("/locations", token);
}
export async function getLocation(location, token) {
    return await customResponse(`/locations/${location.id}`, token);
}
export async function getLocationsOfRelation(relation, token) {
    return await customResponse(`/relations/${relation.id}/locations`, token);
}
export async function getTaskGroups(location, division, frequency, parent, date, token) {
    const params = {};
    if (division !== null) {
        params.division = `${division.id}`;
    }
    if (frequency !== null) {
        params.frequency_type = frequency;
    }
    if (date !== null) {
        params.date = date.toISOString();
    }
    if (parent !== null) {
        params.parent = `${parent}`;
    }
    const taskGroups = await customResponse(`/locations/${location.id}/taskgroups`, token, params);
    let result = taskGroups.map((group) => (Object.assign(Object.assign({}, group), { execution_date: DateTime.fromISO(group.execution_date), finished_date: DateTime.fromISO(group.finished_date), type: "parent" in group ? "redo" : "initial" })));
    return result;
}
export async function getTaskGroup(locationId, taskGroupId, token) {
    const information = await customResponse(`/locations/${locationId}/taskgroups/${taskGroupId}`, token);
    information.execution_date = DateTime.fromISO(information.execution_date);
    if (information.finished_date !== null) {
        information.finished_date = DateTime.fromISO(information.finished_date);
    }
    return information;
}
export async function getTaskGroupPlanForm(locationId, taskGroupId, token) {
    const planData = await customResponse(`/locations/${locationId}/taskgroups/${taskGroupId}/plan`, token);
    return planData;
}
export async function planTaskGroup(locationId, taskGroupId, data, token) {
    return await customResponse(`/locations/${locationId}/taskgroups/${taskGroupId}/plan`, token, undefined, JSON.stringify(data));
}
export async function createTaskGroup(data, token) {
    return await customResponse(`/locations/${data.location.id}/taskgroups`, token, undefined, JSON.stringify(data));
}
async function customResponse(endpoint, token, queryParams = undefined, postData = undefined) {
    const requestUrl = new URL(`${definedUrl}/api3${endpoint}`);
    requestUrl.search = new URLSearchParams(queryParams).toString();
    const headers = {
        "X-LegionellaDossier-Integration": token,
        "Accept-Language": "en",
    };
    let response;
    if (postData !== undefined) {
        response = await fetch(requestUrl.toString(), {
            headers: headers,
            method: "POST",
            body: postData,
        });
    }
    else {
        response = await fetch(requestUrl.toString(), {
            headers: headers,
        });
    }
    const result = await response.json();
    requestLog.update((n) => [
        {
            id: uuidv4(),
            endpoint: endpoint,
            queryParams: queryParams,
            headers: headers,
            result: result,
            response: response,
            data: postData !== undefined ? JSON.parse(postData) : undefined,
            date: DateTime.local(),
        },
        ...n,
    ]);
    if ("error" in result) {
        throw result;
    }
    return result;
}
export const frequencyItems = [
    { value: null, text: "---" },
    { value: "none", text: "None" },
    { value: "1_day", text: "Daily" },
    { value: "1_week", text: "Weekly" },
    { value: "2_week", text: "Bi-weekly" },
    { value: "1_month", text: "Monthly" },
    { value: "3_month", text: "Every 3 months" },
    { value: "6_month", text: "Every 6 months" },
    { value: "1_year", text: "Anually" },
    { value: "2_year", text: "Bi-anually" },
    { value: "3_year", text: "Every 3 years" },
    { value: "5_year", text: "Every 5 years" },
    { value: "10_year", text: "Every 10 years" },
];
