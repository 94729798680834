import { writable } from "svelte/store";
import { authToken } from "./auth";
import { getJsonFromLocalStorage } from "../util/localStorageHelper";
const storedLocations = getJsonFromLocalStorage("locations", []);
let storedSelectedLocation = getJsonFromLocalStorage("locations_selected", null);
if (storedSelectedLocation !== null) {
    storedSelectedLocation = storedLocations.find((location) => location.id === storedSelectedLocation.id);
}
export const locations = writable(storedLocations);
export const selectedLocation = writable(storedSelectedLocation);
let currentValue = undefined;
authToken.subscribe((value) => {
    if (currentValue === undefined) {
        currentValue = value;
    }
    if (currentValue !== value) {
        currentValue = value;
        locations.set([]);
        selectedLocation.set(null);
    }
});
