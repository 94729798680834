<script lang="ts">
  import { Button } from "smelte";
  export let moveUp: Function;
  export let moveDown: Function;
  export let remove: Function;
  export let position: number;
  export let length: number;
</script>

<div class="flex justify-end mb-4">
  <div class="flex-initial mr-4">
    <Button
      icon={'arrow_upward'}
      disabled={position <= 0}
      on:click={(e) => {
        moveUp();
        e.preventDefault();
      }}
      small />
  </div>
  <div class="flex-initial mr-4">
    <Button
      icon={'arrow_downward'}
      disabled={position + 1 == length}
      on:click={(e) => {
        moveDown();
        e.preventDefault();
      }}
      small />
  </div>
  <div class="flex-initial">
    <Button
      icon={'clear'}
      on:click={(e) => {
        e.preventDefault();
        remove();
      }}
      small />
  </div>
</div>
