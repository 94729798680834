<script lang="ts">
  import { createProps, defaultValue } from "@pyoner/svelte-form/src/helpers";
  import { DatePicker, Select, RadioButtonGroup, TextField } from "smelte";
  import { DateTime } from "luxon";

  type T = string;
  const p = createProps<T>();
  export let value = p.value;
  export let errors = p.errors;
  export let schema = p.schema;
  export let components = p.components;
  export let props = p.props;
  /* recalc default value */
  $: if (schema && value == null) {
    value = defaultValue<T>(schema, value);
  }
  let format = "";
  $: format = (schema && schema.format) || "";

  let options = undefined;

  if ("oneOf" in schema) {
    if ("widget" in schema && "choice-expanded" === schema.widget) {
      options = schema.oneOf.map(({ const: constValue, title }) => ({
        value: constValue,
        label: title,
      }));
    } else {
      options = schema.oneOf.map(({ const: constValue, title }) => ({
        value: constValue,
        text: title,
      }));
    }
  }
</script>

{#if options !== undefined}
  {#if 'widget' in schema && 'choice-expanded' === schema.widget}
    <label>{schema.title}</label>
    <RadioButtonGroup bind:selected={value} items={options} />
  {:else}
    <div class="mt-2 mb-6 relative text-error-500 dark:text-gray-100">
      <Select items={options} label={schema.title} bind:value />
      {#if Array.isArray(errors)}
        {#each errors as error}
          {#if error.keyword !== 'const'}
            <div
              class="text-xs py-1 p1-4 absolute bottom-1 left-0 text-error-500">
              {error.message}
            </div>
          {/if}
        {/each}
      {/if}
    </div>
  {/if}
{:else if format === 'date'}
  <div class="mt-2 mb-6 relative text-error-500 dark:text-gray-100">
    <DatePicker
      value={new Date(value)}
      on:change={(i) => {
        {
          switch (format) {
            case 'date':
              value = DateTime.fromJSDate(i.detail).toISODate();
              break;
            case 'date-time':
              value = i.detail.toISOString();
              break;
          }
        }
      }}
      label={schema.title} />
    {#if errors !== null && errors !== undefined && errors.length > 0}
      {#each errors as error}
        <div class="text-xs py-1 p1-4 absolute bottom-1 left-0 text-error-500">
          {error.message}
        </div>
      {/each}
    {/if}
  </div>
{:else}
  <TextField label={schema.title} bind:value />
{/if}
