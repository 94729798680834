<script lang="ts">
  import { requestLog } from "../stores/log";
  import { List, Dialog } from "smelte";
  import { slide } from "svelte/transition";
  import { DateTime } from "luxon";
  import JSONTree from "svelte-json-tree";

  let selected;

  let items = [];

  requestLog.subscribe((logs) => {
    items = logs.map((log) => ({
      text: log.endpoint,
      icon: log.response.ok ? "done" : "clear",
      subheading: `${log.response.statusText} - ${log.date.toLocaleString(
        DateTime.DATETIME_MED_WITH_SECONDS
      )}`,
      id: log,
    }));
  });
</script>

{#if items.length > 0}
  <div in:slide>
    <List {items} dense bind:value={selected} />
  </div>
{/if}

<Dialog
  bind:value={selected}
  classes="items-center z-50 rounded bg-white dark:bg-dark-400 p-4 elevation-4 max-w-95">
  <div class="max-h-3/4-screen overflow-auto">
    <JSONTree value={selected} />
  </div>
</Dialog>
