<script lang="ts">
  import { authToken } from "../stores/auth";
  import { getLocation, getTaskGroups, frequencyItems } from "../api";
  import { selectedLocation } from "../stores/locations";
  import { taskGroups, selectedTaskGroup } from "../stores/task_groups";
  import { Button, Select, DatePicker, List, TextField } from "smelte";
  import { slide } from "svelte/transition";
  import ProgressCircular from "smelte/src/components/ProgressCircular/ProgressCircular.svelte";
  import { DateTime } from "luxon";
  import CreateNewGroup from "./create_new_group.svelte";

  let divisions = [
    {
      value: null,
      text: "---",
    },
  ];
  let frequencies = frequencyItems;

  let currentLocation = null;
  let division = null;
  let parent = null;
  let frequency = null;
  let date = new Date();
  let hasSearched = false;

  selectedLocation.subscribe(async (location) => {
    if (location === null) {
      return;
    }
    if (currentLocation !== null && currentLocation.id === location.id) {
      return;
    }
    currentLocation = location;
    taskGroups.set([]);
    hasSearched = false;
    const locationInformation = await getLocation(location, $authToken);
    divisions = [
      {
        value: null,
        text: "---",
      },
      ...locationInformation.divisions.map((division) => ({
        value: division,
        text: division.name,
      })),
    ];
  });

  let searching = false;

  async function search() {
    searching = true;
    const foundGroups = await getTaskGroups(
      currentLocation,
      division,
      frequency,
      parent,
      date,
      $authToken
    );
    taskGroups.set(foundGroups);
    hasSearched = true;
    searching = false;
  }

  let taskGroupItems = [];

  taskGroups.subscribe((groups) => {
    if (groups === null) {
      return;
    }
    taskGroupItems = groups.map((group) => ({
      id: group.id,
      text: `${group.division?.name || group.management_type} in phase ${
        group.status
      } (#${group.id})`,
      subheading: `${group.execution_date.toLocaleString(
        DateTime.DATE_FULL
      )} (frequency: ${
        group.type === "initial"
          ? group.frequency_type
          : group.type === "redo"
          ? group.parent.id
          : "?"
      })`,
      to: `#/location/${group.location.id}/task-group/${group.id}`,
    }));
  });
</script>

{#if currentLocation !== null}
  <div in:slide out:slide>
    <div class="flex justify-center mt-4">
      <div class="flex-auto mr-4">
        <Select label="Frequency" items={frequencies} bind:value={frequency} />
      </div>
      <div class="flex-auto mr-4">
        <DatePicker bind:value={date} label="Date in period" />
      </div>
      <div class="flex-auto mr-4">
        <Select label="Division" items={divisions} bind:value={division} />
      </div>
      <div class="flex-auto">
        <TextField label="Parent" bind:value={parent} type="number" />
      </div>
      <div class="flex-initial ml-4 self-center">
        <Button icon="search" flat small on:click={search} />
      </div>
    </div>
  </div>
{/if}

{#if searching}
  <div in:slide out:slide class="flex items-center justify-center mt-4">
    <ProgressCircular />
  </div>
{/if}

{#if !searching && taskGroupItems.length > 0}
  <div in:slide out:slide>
    <List items={taskGroupItems} />
  </div>
{/if}

{#if currentLocation !== null && taskGroupItems.length === 0}
  {#if hasSearched}
    <div in:slide out:slide class="flex flex-col align-center mt-16">
      <p class=" text-xl text-center">No groups found.</p>

      <CreateNewGroup
        location={{
          ...currentLocation,
          divisions: divisions
            .map(({ value }) => value)
            .filter((value) => value !== null),
        }}
        {division}
        token={$authToken}
      />
    </div>
  {:else if !searching}
    <div in:slide out:slide class="flex justify-center mt-16">
      <p class="text-xl text-center">
        Enter search parameters and press the search button.
      </p>
    </div>
  {/if}
{/if}
