import { writable } from "svelte/store";
const storedUrl = localStorage.getItem("url");
export const url = writable(storedUrl || "https://app.legionelladossier.com");
url.subscribe((value) => {
    if (value !== null) {
        localStorage.setItem("url", value);
    }
    else {
        localStorage.setItem("url", "");
    }
});
